import { ComponentStyleConfig } from "@chakra-ui/react";
import { ThemeColor } from "../palette";

export const Link: ComponentStyleConfig = {
    baseStyle: {
        color: "gray.500",
        _hover: {
            color: ThemeColor.ACTION_PRIMARY,
            textDecoration: "none",
            "& svg": {
                fill: ThemeColor.ACTION_PRIMARY,
            },
        },
    },
};
