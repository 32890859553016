import { ComponentStyleConfig } from "@chakra-ui/react";
import { ThemeColor } from "../palette";

export const Tabs: ComponentStyleConfig = {
    variants: {
        "solid-rounded": {
            tablist: {
                bg: ThemeColor.GENERAL,
                borderRadius: "0rem",
                p: "0.4rem",
            },
            tab: {
                color: "gray.500",
                borderRadius: "0rem",
                _selected: {
                    color: ThemeColor.CONTENT,
                    bg: ThemeColor.MID_RANGE,
                },
            },
            tabpanel: {
                p: 0,
                mt: 4,
            },
        },
    },
};
