import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { TemplateContext } from "./Template";

export const Column = ({ children, ...props }: BoxProps) => {
    const { rowGap } = React.useContext(TemplateContext);
    return (
        <Box
            display={{ base: "contents", lg: "flex" }}
            flexDirection={"column"}
            rowGap={rowGap ?? props.rowGap}
            {...props}
        >
            {children}
        </Box>
    );
};
