import {
    Button,
    Checkbox,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import React from "react";

const TERMS_COOKIE_NAME = "terms-accepted-date";

export const TermsModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [accepted, setAccepted] = React.useState(!!Cookies.get(TERMS_COOKIE_NAME));

    const onEnter = () => {
        Cookies.set(TERMS_COOKIE_NAME, new Date().toUTCString(), { expires: 3650 });
        onClose();
    };

    React.useEffect(() => {
        !accepted && onOpen();
    }, [accepted]);

    return (
        <Modal
            size={"6xl"}
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            autoFocus={false}
            scrollBehavior={"inside"}
            isCentered
        >
            <ModalOverlay />
            <ModalContent mt={{ base: 0, sm: 8, md: 16 }}>
                <ModalHeader textAlign={"center"}>Warning</ModalHeader>
                <ModalBody pt={0}>
                    <Stack>
                        <Text textAlign={"center"}>
                            No representation or warranty is made concerning any aspect of theGoblins Protocol,
                            including its suitability, quality, availability, accessibility, accuracy or safety. Your
                            access to and use of theGoblins Protocol through this Interface is entirely at risk and
                            could lead to substantial losses, for which you take full responsibility.
                        </Text>
                        <Text textAlign={"center"}>
                            This interface is not available to residents of Belarus, the Central African Republic, the
                            Democratic Republic of Congo, the Democratic People’s Republic of Korea, the Crimea region
                            of Ukraine, Cuba, Iran, Libya, the People’s Republic of China, the Russian Federation,
                            Somalia, Sudan, South Sudan, Syria, the United States of America, Yemen, and Zimbabwe or any
                            other jurisdiction in which accessing or using theGoblins Protocol is prohibited
                            (“Prohibited Jurisdictions”). In using this Interface, you confirm that you are not located
                            in, incorporated or otherwise established in, or resident of, a Prohibited Jurisdiction.
                        </Text>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w={"100%"}>
                        <Checkbox
                            colorScheme={"yellow"}
                            isChecked={accepted}
                            onChange={({ target }) => setAccepted(target.checked)}
                        >
                            I confirm that I have read, understand and accept those terms and their associated risks.
                        </Checkbox>
                        <Button onClick={onEnter} isDisabled={!accepted}>
                            Enter
                        </Button>
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
