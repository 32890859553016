import { ComponentStyleConfig } from "@chakra-ui/react";
import { ThemeColor } from "../palette";

const numericStyles = {
    "&[data-is-numeric=true]": {
        textAlign: "end",
    },
};

export const Table: ComponentStyleConfig = {
    sizes: {
        sm: {
            th: {
                py: "0.8rem",
            },
            td: {
                py: "0.8rem",
            },
        },
        md: {
            th: {
                py: "1rem",
            },
            td: {
                py: "1rem",
            },
        },
        lg: {
            th: {
                py: "1.2rem",
            },
            td: {
                py: "1.2rem",
            },
        },
    },
    variants: {
        info: {
            th: {
                fontSize: "1.2rem",
                fontWeight: "semibold",
                textTransform: "none",
                color: "gray.500",
                "&:first-of-type": {
                    pl: 0,
                },
                "&:last-of-type": {
                    pr: 0,
                },
                ...numericStyles,
            },
            td: {
                color: "gray.500",
                "&:first-of-type": {
                    pl: 0,
                },
                "&:last-of-type": {
                    pr: 0,
                    color: ThemeColor.CONTENT,
                    fontWeight: "semibold",
                },
                ...numericStyles,
            },
            tr: {
                borderBottom: "1px",
                borderColor: ThemeColor.LINES,
            },
            tbody: {
                tr: {
                    "&:last-of-type": {
                        borderBottom: 0,
                    },
                },
            },
        },
    },
};
