import React from "react";
import { Heading as ChakraHeading, HeadingProps } from "@chakra-ui/react";

const getSize = (as: HeadingProps["as"]) => {
    switch (as) {
        case "h1":
            return { base: "7.2rem", md: "8rem" };
        case "h2":
            return { base: "3.42rem", md: "3.8rem" };
        case "h3":
            return { base: "2.88rem", md: "3.2rem" };
        case "h4":
            return { base: "2.16rem", md: "2.4rem" };
        case "h5":
            return { base: "1.8rem", md: "2rem" };
        default:
            return { base: "1.6rem", md: "1.6rem" };
    }
};

export const Heading = ({ children, as = "h2", ...props }: HeadingProps) => {
    const size = getSize(as);
    return (
        <ChakraHeading as={as} fontSize={size} lineHeight={size} {...props}>
            {children}
        </ChakraHeading>
    );
};
