import { appConfig } from "../../config";
import { RestClient } from "../client";
import { CoinId, CoinResponse } from "./types";

interface ICoingeckoApiService {
    getCoin: (coinId: CoinId) => Promise<CoinResponse>;
}

export class CoingeckoApiService implements ICoingeckoApiService {
    constructor(private restClient = new RestClient(appConfig.coingeckoApiBaseUrl)) {}

    public getCoin = (coinId: CoinId): Promise<CoinResponse> => this.restClient.get(`/coins/${coinId}`);
}

export default new CoingeckoApiService();
