export enum ThemeColor {
    ACTION_PRIMARY = "actionPrimary",
    ACTION_PRIMARY_HOVER = "actionPrimaryHover",
    ACTION_CONFIRMATION = "actionConfirmation",
    ACTION_WARNING = "actionWarning",
    ACTION_ERROR = "actionError",
    BACKGROUND = "background",
    CONTENT = "content",
    CARDS = "cards",
    GENERAL = "general",
    LINES = "lines",
    MID_RANGE = "midRange",
    CRYPTO_AVAX = "cryptoAvax",
    CRYPTO_BNB = "cryptoBnb",
    CRYPTO_BTC = "cryptoBtc",
    CRYPTO_ETH = "cryptoEth",
    CRYPTO_MATIC = "cryptoMatic",
    CRYPTO_SAVAX = "cryptoSavax",
    CRYPTO_USDC = "cryptoUsdc",
    CRYPTO_USDT = "cryptoUsdt",
}

export const palette = {
    [ThemeColor.ACTION_PRIMARY]: "#f7bc1f",
    [ThemeColor.ACTION_PRIMARY_HOVER]: "#DEAA1D",
    [ThemeColor.ACTION_CONFIRMATION]: "#5ec88f",
    [ThemeColor.ACTION_WARNING]: "#ffac30",
    [ThemeColor.ACTION_ERROR]: "#ff5d53",
    [ThemeColor.BACKGROUND]: {
        _light: "#f7fafc",
        _dark: "#171923",
    },
    [ThemeColor.CONTENT]: {
        _light: "#1a202c",
        _dark: "#edf2f7",
    },
    [ThemeColor.CARDS]: {
        _light: "#ffffff",
        _dark: "#1a202c",
    },
    [ThemeColor.GENERAL]: {
        _light: "#edf2f7",
        _dark: "#2d3748",
    },
    [ThemeColor.LINES]: {
        _light: "#e2e8f0",
        _dark: "#2d3748",
    },
    [ThemeColor.MID_RANGE]: {
        _light: "#cbd5e0",
        _dark: "#4a5568",
    },
    [ThemeColor.CRYPTO_AVAX]: "#d74f49",
    [ThemeColor.CRYPTO_BNB]: "#f0b90b",
    [ThemeColor.CRYPTO_BTC]: "#f7931a",
    [ThemeColor.CRYPTO_ETH]: "#627eea",
    [ThemeColor.CRYPTO_MATIC]: "#8247e5",
    [ThemeColor.CRYPTO_SAVAX]: "#04B5ED",
    [ThemeColor.CRYPTO_USDC]: "#2775ca",
    [ThemeColor.CRYPTO_USDT]: "#009293",
} as const;
