import React from "react";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { Icon, IconName } from "src/uikit/icon";
import { Link } from "../Link";

export const Footer = () => {
    const renderIconLink = (name: IconName, to: string) => (
        <Link display={"flex"} to={to} target="_blank">
            <Icon name={name} fill={"gray.500"} />
        </Link>
    );

    return (
        <Flex
            as={"footer"}
            minH={"10rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={{ base: "column", md: "row" }}
            py={{ base: "2.1rem", md: 0 }}
        >
            <Text color={"gray.500"} py={"1.1rem"}>
                &copy; {new Date().getFullYear()} theGoblins
            </Text>

            <HStack spacing={{ base: 0, md: 16 }} display={{ base: "contents", md: "flex" }}>
                <HStack spacing={5} py={"1.1rem"}>
                    {renderIconLink("generic.docs", "https://docs.thegoblins.finance")}
                    {/*{renderIconLink("generic.discord", "https://discord.gg/83X5XsuQ")}*/}
                    {renderIconLink("generic.twitter", "https://twitter.com/TheGoblinsFi")}
                    {renderIconLink("generic.github", "https://github.com/The-Goblins")}
                </HStack>
            </HStack>
        </Flex>
    );
};
