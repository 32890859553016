import { Button, Stack, StackProps, Text } from "@chakra-ui/react";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { ContractTransaction } from "ethers";
import { Form, Formik, FormikProps } from "formik";
import { noop } from "lodash";
import React from "react";
import { PromiseResultShape } from "react-use-promise-matcher";
import { Link } from "src/components/Link";
import { TransactionError } from "src/contracts";
import { assets, AssetSymbol } from "src/theme";
import { ThemeColor } from "src/theme/palette";
import { IconName } from "src/uikit/icon";
import { Notice } from "src/uikit/Notice";
import { bigDecimal, BigDecimal, safeParseBigDecimal } from "src/utils/bigDecimal";
import { formatNumber } from "src/utils/format";
import { Maybe } from "true-myth";
import { AmountField } from "./AmountField";
import { TransactionFeedback, TransactionType } from "./TransactionFeedback";

interface FormData {
    amount: string;
}

export interface NoticeProps {
    text: string;
    color?: string;
    icon?: IconName;
}

interface Props extends StackProps {
    transactionType: TransactionType;
    availableAmount: BigDecimal;
    availableAmountSymbol: AssetSymbol;
    allowance?: BigDecimal;
    onTransaction: (amount: BigDecimal) => void;
    approveResult?: PromiseResultShape<ContractTransaction, TransactionError>;
    transactionResult: PromiseResultShape<ContractTransaction, TransactionError>;
    isConnected: boolean;
    isDisabled: boolean;
    isLoading: boolean;
    minAmount?: BigDecimal | null;
    maxAmount?: BigDecimal | null;
    fixedAmount?: BigDecimal | null;
    capRemainingAmount?: BigDecimal | null;
    notice?: NoticeProps | null;
}

export const TransactionForm = ({
    transactionType,
    availableAmount,
    availableAmountSymbol,
    allowance,
    onTransaction,
    approveResult,
    transactionResult,
    isConnected,
    isDisabled,
    isLoading,
    minAmount,
    maxAmount,
    fixedAmount,
    capRemainingAmount,
    notice,
    ...props
}: Props) => {
    const formRef = React.useRef<FormikProps<FormData>>(null);
    const [isTransactionPending, setIsTransactionPending] = React.useState(false);
    const availableAmountDisplaySymbol = React.useMemo(
        () =>
            Maybe.of(assets[availableAmountSymbol]).mapOr(availableAmountSymbol, ({ displaySymbol }) => displaySymbol),
        [availableAmountSymbol],
    );

    const { openConnectModal } = useConnectModal();

    const onSubmit = ({ amount }: FormData) => onTransaction(bigDecimal(amount));

    const onTransactionStarted = () => {
        setIsTransactionPending(true);
    };

    const onTransactionFinished = (resetForm: boolean = true) => {
        resetForm && formRef.current && formRef.current.resetForm();
        setIsTransactionPending(false);
    };

    const renderForm = ({ values, isValid, errors, dirty }: FormikProps<FormData>) => {
        return (
            <Form>
                <Stack spacing={4}>
                    <AmountField
                        name={"amount"}
                        availableAmount={availableAmount}
                        symbol={availableAmountSymbol}
                        displaySymbol={availableAmountDisplaySymbol}
                        isDisabled={isDisabled || isLoading || isTransactionPending || !!fixedAmount}
                        minAmount={minAmount}
                        maxAmount={maxAmount}
                        capRemainingAmount={capRemainingAmount}
                        maxButtonEnabled={transactionType !== "migration"}
                    />
                    {errors.amount && (
                        <Notice color={ThemeColor.ACTION_ERROR} icon={"generic.warning"}>
                            {errors.amount}
                        </Notice>
                    )}
                    {isConnected && (
                        <Button
                            size={"lg"}
                            type={"submit"}
                            isDisabled={isDisabled || !dirty || !isValid || isTransactionPending}
                            isLoading={isLoading}
                            borderRadius={0}
                        >
                            {allowance &&
                            safeParseBigDecimal(values.amount).mapOr(false, amount => amount.gt(allowance))
                                ? "Approve"
                                : transactionType === "deposit"
                                ? "Deposit"
                                : transactionType === "migration"
                                ? "Migrate"
                                : "Withdraw"}
                        </Button>
                    )}
                </Stack>
            </Form>
        );
    };

    const renderTransactionError = ({ reason }: TransactionError) => {
        if (reason == undefined) {
            return (
                <Notice color={ThemeColor.ACTION_WARNING} icon={"generic.warning"}>
                    Transaction declined in wallet.
                </Notice>
            );
        } else {
            return (
                <Notice color={ThemeColor.ACTION_WARNING} icon={"generic.warning"}>
                    Transaction failed: {reason.replace("execution reverted: ", "")}
                </Notice>
            );
        }
    };

    React.useEffect(() => {
        Maybe.of(fixedAmount).match({
            Just: amount => formRef.current?.setFieldValue("amount", amount.gt(0) ? amount.toString() : ""),
            Nothing: noop,
        });
    }, [fixedAmount]);

    return (
        <Stack spacing={4} {...props}>
            {notice && (
                <Notice color={notice.color ?? ThemeColor.ACTION_PRIMARY} icon={notice.icon ?? "generic.info"}>
                    {notice.text}
                </Notice>
            )}
            {approveResult &&
                approveResult.match({
                    Loading: () => <></>,
                    Resolved: transaction => (
                        <TransactionFeedback
                            transactionType={transactionType}
                            loadingContent={`Your approval transaction is pending.`}
                            successContent={`Your approval transaction was successful. You can now ${
                                transactionType === "migration" ? "migrate" : "deposit"
                            }.`}
                            failedContent={`Your approval transaction failed.`}
                            transaction={transaction}
                            onLoading={onTransactionStarted}
                            onSuccess={() => onTransactionFinished(false)}
                            onError={() => onTransactionFinished(false)}
                        />
                    ),
                    Rejected: renderTransactionError,
                })}
            {transactionResult.match({
                Loading: () => <></>,
                Resolved: transaction => {
                    const amount = formatNumber(Number(formRef.current?.values.amount), {
                        suffix: availableAmountDisplaySymbol,
                        joinSeparator: " ",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 8,
                    });
                    const subject = `${transactionType} of ${amount}`;

                    return (
                        <TransactionFeedback
                            transactionType={transactionType}
                            loadingContent={`Your ${subject} is pending.`}
                            successContent={
                                <Text>
                                    Your {subject} was successful.
                                    {transactionType === "migration" && (
                                        <Link to={"/"}>You can now see your position on the V2 page.</Link>
                                    )}
                                </Text>
                            }
                            failedContent={`Your ${subject} failed.`}
                            transaction={transaction}
                            onLoading={onTransactionStarted}
                            onSuccess={onTransactionFinished}
                            onError={onTransactionFinished}
                        />
                    );
                },
                Rejected: renderTransactionError,
            })}
            <Formik<FormData>
                innerRef={formRef}
                initialValues={{ amount: "" }}
                onSubmit={onSubmit}
                validateOnChange={true}
            >
                {renderForm}
            </Formik>
            {openConnectModal && (
                <Button
                    size={"lg"}
                    onClick={openConnectModal}
                    borderRadius={0}
                    borderWidth={0.5}
                    borderColor={ThemeColor.LINES}
                >
                    Connect Wallet
                </Button>
            )}
        </Stack>
    );
};
