import { Styles } from "@chakra-ui/theme-tools";
import { ThemeColor } from "./palette";

export const styles: Styles = {
    global: {
        html: {
            fontSize: "62.5%",
        },
        body: {
            bg: ThemeColor.BACKGROUND,
            color: ThemeColor.CONTENT,
            fontSize: "md",
        },
    },
};
