import { Maybe, Result } from "true-myth";
import { identity } from "ramda";

export const ensure = <T>(value: T | undefined | null): T =>
    Maybe.of<T>(value)
        .map(identity)
        .unwrapOrElse(() => {
            throw TypeError("Failed to ensure value");
        });

export const promiseFromResult = <T, E = string>(result: Result<T, E>): Promise<T> =>
    new Promise<T>((resolve, reject) =>
        result.match({
            Ok: resolve,
            Err: reject,
        }),
    );
