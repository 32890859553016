import { Strategy, StrategyClient } from "../types";
import { BigDecimal, BIG_MAX_UINT256 } from "../../utils/bigDecimal";
import { promiseFromResult } from "../../utils/data";
import { ContractTransaction } from "ethers";
import { Alpha1TheCPPIooorV1Client } from "./Alpha1TheCPPIooorV1Client";

export class Alpha1TheCPPIooorV2Client
    extends Alpha1TheCPPIooorV1Client<Strategy.Alpha1TheCPPIooorV2>
    implements StrategyClient<Strategy.Alpha1TheCPPIooorV2>
{
    getAllowance = async (account: string): Promise<BigDecimal> => {
        const allowance = await this.depositTokenContract.allowance(account, this.contractAddress);
        const converter = await this.getDepositTokenUnitConverter();

        return promiseFromResult(converter.parse(allowance));
    };

    approveSpend = async (account: string, amount?: BigDecimal): Promise<ContractTransaction> => {
        const converter = await this.getDepositTokenUnitConverter();
        const approveAmount = amount ? await promiseFromResult(converter.format(amount)) : BIG_MAX_UINT256.toFixed();

        return this.depositTokenContract.approve(this.contractAddress, approveAmount, { from: account });
    };

    deposit = async (account: string, amount: BigDecimal): Promise<ContractTransaction> => {
        const converter = await this.getDepositTokenUnitConverter();
        const depositAmount = await promiseFromResult(converter.format(amount));

        return this.contract.deposit(depositAmount, { from: account });
    };
}
