import { Table, TableProps, Tbody, Td, Tr, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { ContractItem } from "src/rest/internal";
import { formatAddress } from "../../../utils/format";
import { StrategyProperties } from "../../../strategies.config";
import { Maybe } from "true-myth";
import { ThemeColor } from "src/theme/palette";
import { AppConfigContext } from "../../../context";
import { Strategy } from "../../../contracts";
import { Link } from "../../Link";
import { Icon } from "src/uikit/icon";

interface Props<T extends Strategy> extends TableProps {
    strategy: T;
    data: ContractItem[];
}

export const AddressTable = <T extends Strategy>({ strategy, data, ...props }: Props<T>) => {
    const { strategies } = React.useContext(AppConfigContext);

    const getContractHref = ({ chain }: StrategyProperties, contractAddress: string): string | null =>
        Maybe.of(chain.explorerUrl).mapOr(null, url => `${url}address/${contractAddress}`);

    const renderExtra = (text: string, to: string | null, target: React.HTMLAttributeAnchorTarget = "_blank") => (
        <HStack justifyContent={"flex-end"}>
            <Text>{text}</Text>
            {to && (
                <Link to={to} target={target}>
                    <Icon name={"generic.link"} fill={ThemeColor.ACTION_PRIMARY} w={"1.5rem"} h={"1.5rem"} />
                </Link>
            )}
        </HStack>
    );

    return (
        <Table variant={"info"} {...props}>
            <Tbody>
                {data.map(({ name, address }) => (
                    <Tr key={`${name}${address}`}>
                        <Td lineHeight={10}>{name}</Td>
                        <Td lineHeight={10} textAlign={"right"}>
                            {renderExtra(
                                formatAddress(address.toUpperCase(), 5, 3),
                                getContractHref(strategies[strategy], address),
                            )}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
