import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps } from "src/uikit";
import { Heading } from "src/uikit/typography";

interface OverviewTableRow {
    title: string;
    content: React.ReactNode;
    tooltip: string;
}

interface Props extends CardProps {
    body: React.ReactNode;
    tableContent: OverviewTableRow[];
}

export const Overview = ({ body, ...props }: Props) => {
    return (
        <Card {...props}>
            <Flex direction={"column"}>
                <Heading as={"h4"} color={ThemeColor.ACTION_PRIMARY}>
                    Overview
                </Heading>
                <Box mt={4}>{body}</Box>
            </Flex>
        </Card>
    );
};
