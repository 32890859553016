import { ComponentStyleConfig, Tooltip as ChakraTooltip } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/theme-tools";
import { ThemeColor } from "../palette";

const bg = cssVar("tooltip-bg");
const arrowBg = cssVar("popper-arrow-bg");

ChakraTooltip.defaultProps = {
    hasArrow: true,
};

export const Tooltip: ComponentStyleConfig = {
    baseStyle: {
        bg: bg.reference,
        [bg.variable]: `colors.${ThemeColor.GENERAL}`,
        [arrowBg.variable]: bg.reference,
        _dark: {
            [bg.variable]: `colors.${ThemeColor.GENERAL}`,
        },
        color: "gray.500",
        h6: {
            color: ThemeColor.CONTENT,
        },
        maxWidth: "18rem",
        p: "1rem",
        borderRadius: "0rem",
    },
};
