import { Flex, Heading, GridItem, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "../../../theme/palette";

interface Props {
    title: string;
    subtitle: string;
    url: string;
    comingSoon: boolean;
}

export const GridBox = ({ title, subtitle, url, comingSoon }: Props) => {
    const renderSubtitle = () => {
        if (subtitle != "") {
            if (comingSoon) {
                return (
                    <div>
                        <Text display={{ base: "none", md: "block" }} textAlign={"center"}>
                            ({subtitle})
                        </Text>
                        <Text display={{ base: "block", md: "none" }} textAlign={"center"}>
                            (Soon)
                        </Text>
                    </div>
                );
            } else {
                return (
                    <div>
                        <Text display={{ base: "none", md: "block" }} textAlign={"center"}>
                            ({subtitle})
                        </Text>
                    </div>
                );
            }
        }
    };

    return (
        <GridItem
            w={"100%"}
            borderColor={ThemeColor.ACTION_PRIMARY}
            borderWidth={1}
            _hover={{
                bg: ThemeColor.ACTION_PRIMARY,
                transitionDuration: "0.4s",
                transitionTimingFunction: "ease-in-out",
            }}
        >
            <LinkBox>
                <Flex align={"center"} justifyContent={"center"} padding={{ base: 5, sm: 15, md: 50 }}>
                    <LinkOverlay href={url}>
                        <Heading as={"h2"} textAlign={"center"}>
                            {title}
                        </Heading>
                        {renderSubtitle()}
                    </LinkOverlay>
                </Flex>
            </LinkBox>
        </GridItem>
    );
};
