import { Box, BoxProps, Text } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import React from "react";
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Legend } from "recharts";
import { PerformanceData } from "src/rest/internal";
import { ThemeColor } from "src/theme/palette";

interface Props extends BoxProps {
    data: PerformanceData["historicalPerformance"];
}

export const ChartAlpha2 = ({ data, ...props }: Props) => {
    const { palette } = useTheme();

    const xAxisTickFormatter = (tick: Date) => {
        const day = tick.toLocaleString("en-US", { day: "numeric" });
        const month = tick.toLocaleString("en-US", { month: "short" });
        return `${day} ${month}`;
    };

    const yAxisTickFormatter = (tick: number) => {
        return tick.toFixed(0);
    };

    const legendFormatter = () => {
        const name = "Goblin Bank: Returns";
        return (
            <Text as={"span"} color={"gray.500"}>
                {name}
            </Text>
        );
    };

    return (
        <Box fill={"gray.400"} stroke={ThemeColor.LINES} {...props}>
            <ResponsiveContainer width={"100%"} height={220}>
                <AreaChart data={data.performance}>
                    <defs>
                        <linearGradient id={"gradientLive"} x1={"0"} y1={"0"} x2={"0"} y2={"1"}>
                            <stop offset={"5%"} stopColor={palette.actionWarning} stopOpacity={0.2} />
                            <stop offset={"95%"} stopColor={palette.actionWarning} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray={"1 2"} stroke={"inherit"} />
                    <XAxis
                        dataKey={"date"}
                        tick={{ fill: "inherit", fontSize: "1.2rem" }}
                        tickFormatter={xAxisTickFormatter}
                        stroke={"inherit"}
                        dx={0}
                        dy={10}
                    />
                    <YAxis
                        tick={{ fill: "inherit", fontSize: "1.2rem" }}
                        stroke={"inherit"}
                        width={30}
                        domain={["dataMin", "dataMax"]}
                        tickFormatter={yAxisTickFormatter}
                    />
                    <Area dataKey={"live"} stroke={palette.actionWarning} fillOpacity={1} fill={"url(#gradientLive)"} />
                    <Legend iconType={"plainline"} formatter={legendFormatter} />
                </AreaChart>
            </ResponsiveContainer>
        </Box>
    );
};
