import { SimpleGrid, Center } from "@chakra-ui/react";
import React from "react";
import { GridBox } from "./components/GridBox";

export const IntroductionGrid = () => {
    return (
        <Center>
            <SimpleGrid columns={[2, 2, 3]} spacing={[2.5, 5, 10]}>
                <GridBox title={"Goblin Bank"} subtitle={"GBANK"} url={"/bank"} comingSoon={false}></GridBox>
                <GridBox
                    title={"Goblin Work Station"}
                    subtitle={"NFT Staking - Coming soon"}
                    url={"#"}
                    comingSoon={true}
                />
                <GridBox
                    title={"Goblin Vaults"}
                    subtitle={"$GGOLD Staking - Coming soon"}
                    url={"#"}
                    comingSoon={true}
                ></GridBox>
                <GridBox
                    title={"Twitter"}
                    subtitle={""}
                    url={"https://twitter.com/TheGoblinsFi"}
                    comingSoon={false}
                ></GridBox>
                <GridBox title={"Discord"} subtitle={"Coming soon"} url={"#"} comingSoon={true}></GridBox>
                <GridBox
                    title={"Docs"}
                    subtitle={""}
                    url={"https://docs.thegoblins.finance"}
                    comingSoon={false}
                ></GridBox>
            </SimpleGrid>
        </Center>
    );
};
