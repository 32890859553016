import { Flex, HStack, Progress, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { assets, AssetSymbol } from "src/theme";
import { ThemeColor } from "src/theme/palette";
import { BigDecimal } from "src/utils/bigDecimal";
import { formatNumber } from "src/utils/format";

interface Props {
    cap: BigDecimal;
    tvl: BigDecimal;
    symbol: AssetSymbol;
}

export const CapProgress = ({ cap, tvl, symbol }: Props) => {
    const percentage = Math.min(tvl.mul(100).div(cap).toNumber(), 100);
    return (
        <Stack spacing={2}>
            <Progress size={"sm"} value={percentage} />
            <Flex justifyContent={"space-between"}>
                <HStack>
                    <Text color={"gray.500"}>TVL</Text>
                    <Text fontWeight={"semibold"}>
                        {formatNumber(tvl.toNumber(), {
                            suffix: assets[symbol].displaySymbol,
                            joinSeparator: " ",
                            maximumFractionDigits: assets[symbol].maximumFractionDigits,
                        })}
                    </Text>
                </HStack>
                <Text color={ThemeColor.ACTION_PRIMARY} fontWeight={"semibold"}>
                    {percentage.toFixed(0)}%
                </Text>
            </Flex>
        </Stack>
    );
};
