import { ContractTransaction } from "ethers";
import React from "react";
import { useStrategyStatsStore } from "src/stores/alphaVaultStatsStore";
import { ThemeColor } from "src/theme/palette";
import { Notice } from "src/uikit";
import { IconName } from "src/uikit/icon";
import { Maybe } from "true-myth";
import { Address, useWaitForTransaction } from "wagmi";
import { shallow } from "zustand/shallow";

const FEEDBACK_TIMEOUT = 10000;

export type TransactionType = "deposit" | "withdrawal" | "migration";

interface NoticeProps {
    content: React.ReactNode;
    icon: IconName;
    color: string;
}

interface Props {
    transactionType: TransactionType;
    loadingContent?: React.ReactNode;
    successContent?: React.ReactNode;
    failedContent?: React.ReactNode;
    transaction: ContractTransaction;
    onLoading?: () => void;
    onSuccess?: () => void;
    onError?: () => void;
}

export const TransactionFeedback = ({
    loadingContent,
    successContent,
    failedContent,
    transaction,
    onLoading,
    onSuccess,
    onError,
}: Props) => {
    const { status } = useWaitForTransaction({ hash: transaction.hash as Address });
    const [notice, setNotice] = React.useState<Maybe<NoticeProps>>(Maybe.nothing());
    const [accountSnapshot, setAccountSnapshot] = React.useState<string | null>(null);

    const stats = useStrategyStatsStore(
        state => ({
            allowance: state.allowance,
            balance: state.balance,
        }),
        shallow,
    );

    React.useEffect(() => {
        switch (status) {
            case "loading":
                onLoading && onLoading();
                setNotice(
                    Maybe.of({
                        content: loadingContent ?? `Your transaction is pending.`,
                        icon: "generic.alarmClock",
                        color: ThemeColor.ACTION_WARNING,
                    }),
                );
                break;
            case "success":
                // transaction succeeded, but user still needs to wait for an update from the contract
                setAccountSnapshot(JSON.stringify(stats));
                break;
            case "error":
                onError && onError();
                setNotice(
                    Maybe.of({
                        content: failedContent ?? `Your transaction failed.`,
                        icon: "generic.warning",
                        color: ThemeColor.ACTION_WARNING,
                    }),
                );
                setTimeout(() => setNotice(Maybe.nothing()), FEEDBACK_TIMEOUT);
                break;
            default:
                setNotice(Maybe.nothing());
        }
    }, [status]);

    React.useEffect(() => {
        if (accountSnapshot && accountSnapshot !== JSON.stringify(stats)) {
            onSuccess && onSuccess();
            setAccountSnapshot(null);
            setNotice(
                Maybe.of({
                    content: successContent ?? `Your transaction was successful.`,
                    icon: "generic.check",
                    color: ThemeColor.ACTION_CONFIRMATION,
                }),
            );
            setTimeout(() => setNotice(Maybe.nothing()), FEEDBACK_TIMEOUT);
        }
    }, [stats]);

    return notice.mapOr(<></>, ({ content, icon, color }) => (
        <Notice color={color} icon={icon}>
            {content}
        </Notice>
    ));
};
