import { ReactComponent as AVAX } from "./assets/crypto/AVAX.svg";
import { ReactComponent as BNB } from "./assets/crypto/BNB.svg";
import { ReactComponent as BTC } from "./assets/crypto/BTC.svg";
import { ReactComponent as BUSD } from "./assets/crypto/BUSD.svg";
import { ReactComponent as ETH } from "./assets/crypto/ETH.svg";
import { ReactComponent as SAVAX } from "./assets/crypto/SAVAX.svg";
import { ReactComponent as USDC } from "./assets/crypto/USDC.svg";
import { ReactComponent as USDT } from "./assets/crypto/USDT.svg";
import { ReactComponent as GGLD } from "./assets/crypto/GGLD.svg";

export const crypto = {
    "crypto.avax": AVAX,
    "crypto.bnb": BNB,
    "crypto.btc": BTC,
    "crypto.busd": BUSD,
    "crypto.eth": ETH,
    "crypto.savax": SAVAX,
    "crypto.usdc": USDC,
    "crypto.usdt": USDT,
    "crypto.gbusdc": GGLD,
};
