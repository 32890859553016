import { ReactComponent as AlarmClock } from "./assets/generic/alarm-clock.svg";
import { ReactComponent as Balance } from "./assets/generic/balance.svg";
import { ReactComponent as Calendar } from "./assets/generic/calendar.svg";
import { ReactComponent as CalendarDate } from "./assets/generic/calendar-date.svg";
import { ReactComponent as CalendarTimeframe } from "./assets/generic/calendar-timeframe.svg";
import { ReactComponent as Cash } from "./assets/generic/cash.svg";
import { ReactComponent as Chain } from "./assets/generic/chain.svg";
import { ReactComponent as Chart } from "./assets/generic/chart.svg";
import { ReactComponent as Check } from "./assets/generic/check.svg";
import { ReactComponent as ChevronDown } from "./assets/generic/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "./assets/generic/chevron-left.svg";
import { ReactComponent as ChevronRight } from "./assets/generic/chevron-right.svg";
import { ReactComponent as ChevronUp } from "./assets/generic/chevron-up.svg";
import { ReactComponent as Clock } from "./assets/generic/clock.svg";
import { ReactComponent as Close } from "./assets/generic/close.svg";
import { ReactComponent as DarkMode } from "./assets/generic/dark-mode.svg";
import { ReactComponent as Discord } from "./assets/generic/discord.svg";
import { ReactComponent as Docs } from "./assets/generic/docs.svg";
import { ReactComponent as Github } from "./assets/generic/github.svg";
import { ReactComponent as Hide } from "./assets/generic/hide.svg";
import { ReactComponent as Info } from "./assets/generic/info.svg";
import { ReactComponent as LightMode } from "./assets/generic/light-mode.svg";
import { ReactComponent as Link } from "./assets/generic/link.svg";
import { ReactComponent as Locked } from "./assets/generic/locked.svg";
import { ReactComponent as Menu } from "./assets/generic/menu.svg";
import { ReactComponent as Notifications } from "./assets/generic/notifications.svg";
import { ReactComponent as Protection } from "./assets/generic/protection.svg";
import { ReactComponent as Risk } from "./assets/generic/risk.svg";
import { ReactComponent as Show } from "./assets/generic/show.svg";
import { ReactComponent as Transact } from "./assets/generic/transact.svg";
import { ReactComponent as Twitter } from "./assets/generic/twitter.svg";
import { ReactComponent as Unlocked } from "./assets/generic/unlocked.svg";
import { ReactComponent as Vault } from "./assets/generic/vault.svg";
import { ReactComponent as Wallet } from "./assets/generic/wallet.svg";
import { ReactComponent as Warning } from "./assets/generic/warning.svg";
import { ReactComponent as Yield } from "./assets/generic/yield.svg";

export const generic = {
    "generic.alarmClock": AlarmClock,
    "generic.balance": Balance,
    "generic.calendar": Calendar,
    "generic.calendarDate": CalendarDate,
    "generic.calendarTimeframe": CalendarTimeframe,
    "generic.cash": Cash,
    "generic.chain": Chain,
    "generic.chart": Chart,
    "generic.check": Check,
    "generic.chevronDown": ChevronDown,
    "generic.chevronLeft": ChevronLeft,
    "generic.chevronRight": ChevronRight,
    "generic.chevronUp": ChevronUp,
    "generic.clock": Clock,
    "generic.close": Close,
    "generic.darkMode": DarkMode,
    "generic.discord": Discord,
    "generic.docs": Docs,
    "generic.hide": Hide,
    "generic.github": Github,
    "generic.info": Info,
    "generic.lightMode": LightMode,
    "generic.link": Link,
    "generic.locked": Locked,
    "generic.menu": Menu,
    "generic.notifications": Notifications,
    "generic.protection": Protection,
    "generic.risk": Risk,
    "generic.show": Show,
    "generic.transact": Transact,
    "generic.twitter": Twitter,
    "generic.unlocked": Unlocked,
    "generic.vault": Vault,
    "generic.wallet": Wallet,
    "generic.warning": Warning,
    "generic.yield": Yield,
};
