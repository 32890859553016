import { Center, Flex, HStack, Spinner, Stack, Table, TableContainer, Tbody, Td, Tr, Text } from "@chakra-ui/react";
import React from "react";
import { shallow } from "zustand/shallow";
import { Strategy } from "src/contracts";
import { ThemeColor } from "src/theme/palette";
import { Asset, Card, CardProps } from "src/uikit";
import { formatNumber } from "src/utils/format";
import { Maybe } from "true-myth";
import { useAccount } from "wagmi";
import { useStrategyStatsStore } from "src/stores/alphaVaultStatsStore";
import { AssetSymbol } from "src/theme";
import { Heading } from "../../uikit/typography";

interface Props<T extends Strategy> extends CardProps {
    strategy: T;
    symbol: AssetSymbol;
}

export const DeployedAmount = <T extends Strategy>({ ...props }: Props<T>) => {
    const { address } = useAccount();

    const stats = useStrategyStatsStore(
        state => ({
            isLoading: state.isLoading,
            cap: state.cap,
            depositToken: state.depositToken,
            iouToken: state.iouToken,
            isPaused: state.isPaused,
            tvl: state.totalValueLocked,
            allowance: state.allowance,
            balance: state.balance,
            depositTokenBalance: state.depositTokenBalance,
            refreshStats: state.refreshStats,
        }),
        shallow,
    );

    console.log("stats", stats);

    const maybeAccount = React.useMemo(() => Maybe.of(address), [address]);

    return (
        <Card {...props}>
            {stats.isLoading ? (
                <Center h={"11.2rem"}>
                    <Spinner />
                </Center>
            ) : (
                <Stack spacing={4}>
                    <Flex justifyContent={"space-between"}>
                        <Heading as={"h4"}>My Position</Heading>
                    </Flex>
                    {maybeAccount.isJust() && (
                        <TableContainer>
                            <Table variant={"info"}>
                                <Tbody>
                                    <Tr>
                                        <Td>
                                            <HStack spacing={4}>
                                                <Asset
                                                    symbol={stats.iouToken.symbol.replace(".", "") as AssetSymbol}
                                                    size={"sm"}
                                                />
                                                <Stack>
                                                    <Heading as={"h4"}>{stats.iouToken.symbol}</Heading>
                                                </Stack>
                                            </HStack>
                                        </Td>
                                        <Td color={ThemeColor.CONTENT} isNumeric>
                                            <Heading as={"h4"}>
                                                {formatNumber(stats.balance.shares.toNumber(), {
                                                    suffix: "",
                                                    joinSeparator: " ",
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 8,
                                                })}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <HStack spacing={4}>
                                                <Asset symbol={stats.depositToken.symbol as AssetSymbol} size={"sm"} />
                                                <Stack>
                                                    <Heading as={"h4"}>USDC.e</Heading>
                                                </Stack>
                                            </HStack>
                                        </Td>
                                        <Td color={ThemeColor.CONTENT} isNumeric>
                                            <Heading as={"h4"}>
                                                {formatNumber(stats.balance.amount.toNumber(), {
                                                    prefix: "~",
                                                    suffix: "",
                                                    joinSeparator: " ",
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    )}
                    {!maybeAccount.isJust() && <Text>Connect your wallet to see your position.</Text>}
                </Stack>
            )}
        </Card>
    );
};
