import { IconName } from "src/uikit/icon";
import { ThemeColor } from "./palette";

export type AssetSymbol =
    | "AVAX"
    | "sAVAX"
    | "BNB"
    | "ETH"
    | "MATIC"
    | "USDC"
    | "USDCe"
    | "USDT"
    | "USDt"
    | "BTCB"
    | "gbUSDCe";

export interface Asset {
    name: string;
    displaySymbol: string;
    icon: IconName;
    color: ThemeColor;
    minimumFractionDigits: number;
    maximumFractionDigits: number;
}

export const assets: Record<AssetSymbol, Asset> = {
    AVAX: {
        name: "Avalanche",
        displaySymbol: "AVAX",
        icon: "chains.avax",
        color: ThemeColor.CRYPTO_AVAX,
        minimumFractionDigits: 0,
        maximumFractionDigits: 18,
    },
    sAVAX: {
        name: "Staked Avalanche",
        displaySymbol: "sAVAX",
        icon: "crypto.savax",
        color: ThemeColor.CRYPTO_SAVAX,
        minimumFractionDigits: 0,
        maximumFractionDigits: 18,
    },
    BNB: {
        name: "BNB",
        displaySymbol: "BNB",
        icon: "crypto.bnb",
        color: ThemeColor.CRYPTO_BNB,
        minimumFractionDigits: 0,
        maximumFractionDigits: 18,
    },
    ETH: {
        name: "Ethereum",
        displaySymbol: "ETH",
        icon: "crypto.eth",
        color: ThemeColor.CRYPTO_ETH,
        minimumFractionDigits: 0,
        maximumFractionDigits: 18,
    },
    MATIC: {
        name: "Polygon",
        displaySymbol: "MATIC",
        icon: "chains.matic",
        color: ThemeColor.CRYPTO_MATIC,
        minimumFractionDigits: 0,
        maximumFractionDigits: 18,
    },
    USDC: {
        name: "USD Coin",
        displaySymbol: "USDC",
        icon: "crypto.usdc",
        color: ThemeColor.CRYPTO_USDC,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    USDCe: {
        name: "USD Coin",
        displaySymbol: "USDC.e",
        icon: "crypto.usdc",
        color: ThemeColor.CRYPTO_USDC,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    USDT: {
        name: "USD Tether",
        displaySymbol: "USDT",
        icon: "crypto.usdt",
        color: ThemeColor.CRYPTO_USDT,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    USDt: {
        name: "USD Tether",
        displaySymbol: "USDT",
        icon: "crypto.usdt",
        color: ThemeColor.CRYPTO_USDT,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    BTCB: {
        name: "Bitcoin",
        displaySymbol: "BTC.b",
        icon: "crypto.btc",
        color: ThemeColor.CRYPTO_BTC,
        minimumFractionDigits: 0,
        maximumFractionDigits: 8,
    },
    gbUSDCe: {
        name: "Goblin Bank USDC",
        displaySymbol: "gbUSDC.e",
        icon: "crypto.gbusdc",
        color: ThemeColor.ACTION_PRIMARY,
        minimumFractionDigits: 0,
        maximumFractionDigits: 8,
    },
};
