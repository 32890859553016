import { palette, ThemeColor } from "./palette";

export type SemanticTokenColor = { _light: string; _dark: string };

type SemanticTokensColors = Record<ThemeColor, string | SemanticTokenColor>;
const colors: SemanticTokensColors = palette; // validates the palette

export const semanticTokens = {
    colors,
    sizes: {
        cardBorderRadius: "1.6rem",
        cardPadding: "2.4rem",
    },
} as const;
