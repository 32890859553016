import React from "react";
import { IntroductionGrid } from "../components";
import { Flex } from "@chakra-ui/react";

export const Home = () => {
    return (
        <>
            <Flex minHeight="80vh" maxHeight="80vh" justifyContent={"center"}>
                <IntroductionGrid />
            </Flex>
        </>
    );
};
