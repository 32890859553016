import { Flex, HStack, Table, TableProps, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import React from "react";
import { EpochPerformanceItem } from "src/rest/internal";
import { ThemeColor } from "src/theme/palette";
import { Icon } from "src/uikit/icon";
import { formatNumber } from "src/utils/format";
import { TooltipLabel } from "../../../uikit";

interface Props extends TableProps {
    data: EpochPerformanceItem[];
}

export const EpochHistoryTable = ({ data, ...props }: Props) => {
    const isCurrentEvent = (startDate: Date, endDate: Date) => {
        const now = new Date();
        return startDate < now && endDate > now;
    };

    return (
        <Table {...props}>
            <Thead>
                <Tr>
                    <Th>Epoch</Th>
                    <Th>Timeframe</Th>
                    <Th isNumeric>Return</Th>
                </Tr>
            </Thead>
            <Tbody>
                {data.map(({ name, startDate, endDate, epochPerformance }, index) => (
                    <Tr key={`${name}${index}`}>
                        <Td>
                            <HStack spacing={2}>
                                <Icon name={"generic.calendarTimeframe"} fill={"gray.500"} w={"2rem"} h={"2rem"} />
                                <Text>{name}</Text>
                            </HStack>
                        </Td>
                        <Td>
                            {isCurrentEvent(startDate, endDate) ? (
                                <Text color={ThemeColor.ACTION_PRIMARY}>In Progress</Text>
                            ) : (
                                <Text>
                                    {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                                </Text>
                            )}
                        </Td>
                        <Td isNumeric>
                            {isCurrentEvent(startDate, endDate) ? (
                                <HStack justifyContent={"flex-end"}>
                                    <Text color={ThemeColor.ACTION_PRIMARY}>
                                        {epochPerformance !== 0
                                            ? formatNumber(epochPerformance, {
                                                  prefix: epochPerformance > 0 ? "+" : "",
                                                  suffix: "%",
                                              })
                                            : `-`}
                                    </Text>
                                    <Tooltip
                                        label={
                                            <TooltipLabel>
                                                This return is updated at the end of every week of the epoch when gains
                                                are realized.
                                            </TooltipLabel>
                                        }
                                    >
                                        <Flex>
                                            <Icon
                                                name={"generic.info"}
                                                fill={ThemeColor.ACTION_PRIMARY}
                                                w={"2rem"}
                                                h={"2rem"}
                                            />
                                        </Flex>
                                    </Tooltip>
                                </HStack>
                            ) : (
                                <Text
                                    color={
                                        epochPerformance > 0 ? ThemeColor.ACTION_CONFIRMATION : ThemeColor.ACTION_ERROR
                                    }
                                >
                                    {formatNumber(epochPerformance, {
                                        prefix: epochPerformance > 0 ? "+" : "",
                                        suffix: "%",
                                    })}
                                </Text>
                            )}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
