import { Alpha1TheCPPIooorV1Abi } from "./generated-types/Alpha1TheCPPIooorV1Abi";
import { Alpha1TheCPPIooorV2Abi } from "./generated-types/Alpha1TheCPPIooorV2Abi";
import { Alpha2SmartFarmooorAbi } from "./generated-types/Alpha2SmartFarmooorAbi";
import { BigDecimal } from "../utils/bigDecimal";
import { BaseContract, ContractTransaction, Transaction } from "ethers";

export enum Strategy {
    Alpha1TheCPPIooorV1 = "ALPHA_1_THE_CPPIOOOR_V1",
    Alpha1TheCPPIooorV2 = "ALPHA_1_THE_CPPIOOOR_V2",
    Alpha2SmartFarmooor = "ALPHA_2_SMART_FARMOOOR",
    Aggregator = "AGGREGATOR",
    NftPreSale = "NFTPRESALE",
}

type AbiTypeMapEntry<T extends Strategy, B extends BaseContract> = Record<T, B>;

type AbiTypeMap = AbiTypeMapEntry<Strategy.Alpha1TheCPPIooorV1, Alpha1TheCPPIooorV1Abi> &
    AbiTypeMapEntry<Strategy.Alpha1TheCPPIooorV2, Alpha1TheCPPIooorV2Abi> &
    AbiTypeMapEntry<Strategy.Alpha2SmartFarmooor, Alpha2SmartFarmooorAbi> &
    AbiTypeMapEntry<Strategy.Aggregator, Alpha2SmartFarmooorAbi> &
    AbiTypeMapEntry<Strategy.NftPreSale, Alpha2SmartFarmooorAbi>;

export type StrategyAbi<T extends Strategy> = AbiTypeMap[T];

export interface SlippageRange {
    minPercentage: BigDecimal;
    maxPercentage: BigDecimal;
}

export interface TVD {
    tvd: BigDecimal;
    usdEquivalent: BigDecimal;
}

export interface Token {
    symbol: string;
    decimals: number;
}

export interface AccountBalance {
    shares: BigDecimal;
    amount: BigDecimal;
}

export interface StrategyClient<T extends Strategy> {
    contract: StrategyAbi<T>;
    getCap: () => Promise<BigDecimal>;
    getCurrentApy?: () => Promise<BigDecimal>;
    getDepositToken: () => Promise<Token>;
    getIOUToken: () => Promise<Token>;
    getIsEpochRunning?: () => Promise<boolean>;
    getIsPaused?: () => Promise<boolean>;
    getSlippageRange?: () => Promise<SlippageRange>;
    getTotalBalance: () => Promise<BigDecimal>;
    getTotalValueDeposited: () => Promise<TVD>;
    getAllowance: (account: string) => Promise<BigDecimal>;
    getAccountBalance: (account: string) => Promise<AccountBalance>;
    getDepositTokenBalance: (account: string) => Promise<BigDecimal>;
    approveSpend: (account: string, amount?: BigDecimal) => Promise<ContractTransaction>;
    deposit: (account: string, amount: BigDecimal, signature?: string) => Promise<ContractTransaction>;
    withdraw: (account: string, amount: BigDecimal, slippage?: BigDecimal) => Promise<ContractTransaction>;
    migrate?: () => Promise<ContractTransaction>;
}

export interface TransactionError {
    reason: string;
    code: string;
    transaction: Transaction;
    action?: string;
    method?: string;
}
