import { Strategy, StrategyAbi, StrategyClient } from "../types";
import React from "react";
import { AppConfigContext } from "../../context";
import { StrategiesConfig } from "../../strategies.config";
import { isNil } from "ramda";
import { Alpha1TheCPPIooorV1Client } from "./Alpha1TheCPPIooorV1Client";
import { Alpha1TheCPPIooorV2Client } from "./Alpha1TheCPPIooorV2Client";
import { Alpha2SmartFarmooorClient } from "./Alpha2SmartFarmooorClient";
import { useContract, useNetwork, useProvider, useSigner } from "wagmi";
import { useStrategyContract } from "../hooks";
import Erc20Abi from "../abi/erc20TokenAbi.json";
import { Erc20TokenAbi } from "../generated-types";
import { Maybe } from "true-myth";

const depositTokenAddressGetter = (config: StrategiesConfig) => (strategy: Strategy) => {
    const address = config[strategy].depositTokenAddress;

    if (isNil(address)) {
        throw new Error(`Missing deposit token contract address for ${strategy}`);
    }

    return address;
};

export const useStrategyClient = <T extends Strategy>(strategy: T): StrategyClient<T> => {
    const { strategies } = React.useContext(AppConfigContext);
    const provider = useProvider();
    const { data: signer } = useSigner();
    const { chain } = useNetwork();
    const signerOrProvider = signer && !chain?.unsupported ? signer : provider;

    const contract = useStrategyContract(strategy, signerOrProvider);

    const getDepositTokenAddress = depositTokenAddressGetter(strategies);

    const depositTokenContract = Maybe.of(
        useContract({
            address: getDepositTokenAddress(strategy),
            abi: Erc20Abi,
            signerOrProvider,
        }),
    ).unwrapOrElse(() => {
        throw new Error("Failed to create deposit token contract");
    }) as Erc20TokenAbi;

    return React.useMemo(() => {
        switch (strategy) {
            case Strategy.Alpha1TheCPPIooorV1:
                return new Alpha1TheCPPIooorV1Client(
                    contract as StrategyAbi<Strategy.Alpha1TheCPPIooorV1>,
                    depositTokenContract,
                    strategies[strategy].address,
                ) as unknown as StrategyClient<T>;
            case Strategy.Alpha1TheCPPIooorV2:
                return new Alpha1TheCPPIooorV2Client(
                    contract as StrategyAbi<Strategy.Alpha1TheCPPIooorV2>,
                    depositTokenContract,
                    strategies[strategy].address,
                ) as unknown as StrategyClient<T>;
            case Strategy.Alpha2SmartFarmooor:
                return new Alpha2SmartFarmooorClient(
                    contract as StrategyAbi<Strategy.Alpha2SmartFarmooor>,
                    depositTokenContract,
                    strategies[strategy].address,
                ) as unknown as StrategyClient<T>;
            case Strategy.Aggregator:
                return new Alpha2SmartFarmooorClient(
                    contract as StrategyAbi<Strategy.Aggregator>,
                    depositTokenContract,
                    strategies[strategy].address,
                ) as unknown as StrategyClient<T>;
            case Strategy.NftPreSale:
                return new Alpha2SmartFarmooorClient(
                    contract as StrategyAbi<Strategy.NftPreSale>,
                    depositTokenContract,
                    strategies[strategy].address,
                ) as unknown as StrategyClient<T>;
            default:
                throw new Error(`Missing StrategyClient implementation for ${strategy}`);
        }
    }, [contract, depositTokenContract]);
};
