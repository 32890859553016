import { Table, TableProps, Tbody, Td, Tr } from "@chakra-ui/react";
import React from "react";
import { Heading } from "../../../uikit/typography";

interface Props extends TableProps {
    day1: number;
    week: number;
    inception: number;
}

export const ApyTable = ({ day1, week, inception, ...props }: Props) => {
    return (
        <Table variant={"info"} {...props}>
            <Tbody>
                <Tr key={`day1`}>
                    <Td>
                        <Heading as={"h6"}>1-day APY</Heading>
                    </Td>
                    <Td textAlign={"right"}>
                        <Heading as={"h6"}>{day1.toFixed(2)}%</Heading>
                    </Td>
                </Tr>
                <Tr key={`week`}>
                    <Td>
                        <Heading as={"h6"}>7-day APY</Heading>
                    </Td>
                    <Td textAlign={"right"}>
                        <Heading as={"h6"}>{week.toFixed(2)}%</Heading>
                    </Td>
                </Tr>
                <Tr key={`inception`}>
                    <Td>
                        <Heading as={"h6"}>Inception APY</Heading>
                    </Td>
                    <Td textAlign={"right"}>
                        <Heading as={"h6"}>{inception.toFixed(2)}%</Heading>
                    </Td>
                </Tr>
            </Tbody>
        </Table>
    );
};
