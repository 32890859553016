import { Strategy } from "src/contracts";
import { appConfig } from "../../config";
import { RestClient } from "../client";
import {
    mapAllocationDataDto,
    mapCalendarDataDto,
    mapContractsDataDto,
    mapExecutionDataDto,
    mapPerformanceDataDto,
} from "./mappers";
import {
    AllocationData,
    AllocationDataDto,
    AllowlistData,
    AllowlistDataDto,
    CalendarData,
    CalendarDataDto,
    ContractsData,
    ContractsDataDto,
    ExecutionData,
    ExecutionDataDto,
    PerformanceData,
    PerformanceDataDto,
} from "./types";

const uriMap: Record<Strategy, string> = {
    [Strategy.Alpha1TheCPPIooorV1]: "alpha1",
    [Strategy.Alpha1TheCPPIooorV2]: "alpha1/v2",
    [Strategy.Alpha2SmartFarmooor]: "alpha2",
    [Strategy.Aggregator]: "aggregator",
    [Strategy.NftPreSale]: "nft",
};

interface IPrivateApiService {
    getAllowlist: (address: string) => Promise<AllowlistData>;
    getCalendar: () => Promise<CalendarData>;
    getPerformance: () => Promise<PerformanceData>;
    getExecution: () => Promise<ExecutionData>;
    getAllocation: () => Promise<AllocationData>;
    getContracts: () => Promise<ContractsData>;
}

export class PrivateApiService implements IPrivateApiService {
    constructor(public strategy: Strategy, private restClient = new RestClient(appConfig.apiBaseUrl)) {}

    private buildUri = (endpoint: string) => `/${uriMap[this.strategy]}/${endpoint}`;

    public getAllowlist = async (address: string): Promise<AllowlistData> =>
        await this.restClient.get<AllowlistDataDto>(`${this.buildUri("allowlist")}?address=${address}`);

    public getCalendar = async (): Promise<CalendarData> =>
        mapCalendarDataDto(await this.restClient.get<CalendarDataDto>(this.buildUri("calendar")));

    public getPerformance = async (): Promise<PerformanceData> =>
        mapPerformanceDataDto(await this.restClient.get<PerformanceDataDto>(this.buildUri("performance")));

    public getExecution = async (): Promise<ExecutionData> =>
        mapExecutionDataDto(await this.restClient.get<ExecutionDataDto>(this.buildUri("execution")));

    public getAllocation = async (): Promise<AllocationData> =>
        mapAllocationDataDto(await this.restClient.get<AllocationDataDto>(this.buildUri("allocation")));

    public getContracts = async (): Promise<ContractsData> =>
        mapContractsDataDto(await this.restClient.get<ContractsDataDto>(this.buildUri("contracts")));
}
