import React from "react";
import { Stack, StackProps } from "@chakra-ui/react";
import { Heading, Note } from "./typography";

interface Props extends StackProps {
    heading?: string;
}

export const TooltipLabel = ({ children, heading, ...props }: Props) => (
    <Stack {...props}>
        {heading && <Heading as={"h6"}>{heading}</Heading>}
        <Note>{children}</Note>
    </Stack>
);
