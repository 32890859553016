export interface FormatNumberOptions extends Intl.NumberFormatOptions {
    prefix?: string;
    suffix?: string;
    joinSeparator?: string;
}

export const formatNumber = (value: number, options?: FormatNumberOptions) =>
    [
        options?.prefix ? options.prefix : "",
        value.toLocaleString("de-CH", options),
        options?.suffix ? options.suffix : "",
    ].join(options?.joinSeparator ?? "");

export const formatAddress = (address: string, leadingChars: number = 6, trailingChars: number = 4): string =>
    address.length < leadingChars + trailingChars
        ? address
        : `${address.substring(0, leadingChars)}\u2026${address.substring(address.length - trailingChars)}`;

export const formatDate = (date: Date) => `${date.getDate()} ${date.toLocaleString("en-US", { month: "short" })}`;

export const formatDateTime = (date: Date) =>
    `${date.getDate()} ${date.toLocaleString("en-US", { month: "short" })} at ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    })}`;
