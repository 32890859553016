import { Flex, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps } from "src/uikit";
import { Heading } from "src/uikit/typography";
import { hexToRGBA } from "src/utils/color";
import { Parameter } from "./components/Parameter";

const Parameters = styled(Flex)`
    border: 1px solid ${({ theme }) => theme.palette.actionPrimary};
    border-radius: 0.8rem;
    color: ${({ theme }) => theme.palette.actionPrimary};
    background-color: ${({ theme }) => hexToRGBA(theme.palette.actionPrimary, 0.1)};

    & > div:not(:first-of-type) {
        border-left: 1px solid ${({ theme }) => theme.palette.actionPrimary};
    }
`;

interface Props extends CardProps {}

export const StrategyDetails = (props: Props) => {
    return (
        <Card {...props}>
            <Flex direction={"column"}>
                <Heading as={"h4"} color={ThemeColor.ACTION_PRIMARY}>
                    Strategy Details
                </Heading>
                <Text mt={4}>🔒 Safe leg: Generate returns without capital risk (passive DeFi yield)</Text>
                <Text mt={0}>
                    📊 Risky leg: Generate returns with trading positions and capital risk (Algo trading)
                </Text>
                <Parameters mt={6}>
                    <Parameter flex={1} label={"Safe Leg"} value={"80%"} icons={["crypto.usdc"]} />
                    <Parameter
                        label={"Risky Leg"}
                        value={"0-20%"}
                        icons={["crypto.btc", "crypto.eth", "chains.avax", "crypto.usdc"]}
                    />
                </Parameters>
            </Flex>
        </Card>
    );
};
