import { Icon as ChakraIcon, IconProps } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { chains } from "./chains";
import { crypto } from "./crypto";
import { generic } from "./generic";

const icons = {
    ...chains,
    ...crypto,
    ...generic,
};

export type IconName = keyof typeof icons;

interface Props extends IconProps {
    name: IconName;
}

export const Icon = ({ name, fill = ThemeColor.CONTENT, w = "2.4rem", h = "2.4rem", ...props }: Props) => (
    <ChakraIcon as={icons[name]} fill={fill} w={w} h={h} {...props} />
);
