import { connectorsForWallets, getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createClient } from "wagmi";
import { arbitrum } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

const { chains, provider } = configureChains([arbitrum], [publicProvider()]);

const { wallets: defaultWallets } = getDefaultWallets({
    appName: "theGoblins",
    chains,
});

const connectors = connectorsForWallets([...defaultWallets]);

export { chains };

export const client = createClient({
    autoConnect: true,
    connectors,
    provider,
});
