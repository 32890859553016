import { ComponentStyleConfig } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { getAlphaColor } from "../utils";

export const Tag: ComponentStyleConfig = {
    sizes: {
        sm: {
            container: {
                minH: "2.4rem",
                fontSize: "1.2rem",
            },
        },
        md: {
            container: {
                minH: "3rem",
                fontSize: "1.6rem",
                paddingInline: "1.2rem",
            },
        },
        lg: {
            container: {
                minH: "3.6rem",
                fontSize: "2rem",
                paddingInline: "1.4rem",
            },
        },
    },
    variants: {
        capsule: ({ color }: StyleFunctionProps) => ({
            container: {
                borderRadius: "full",
                backgroundColor: getAlphaColor(color, 0.2),
            },
        }),
    },
};
