import React from "react";
import { Maybe } from "true-myth";
import { Button, Flex, HStack, Text, useColorMode } from "@chakra-ui/react";
import { ThemeColor } from "src/theme/palette";
import { Icon } from "src/uikit/icon";
import { useAccountModal, useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useNetwork } from "wagmi";
import { formatAddress } from "src/utils/format";
import { assets, AssetSymbol } from "src/theme";
import { Link } from "../Link";
import { Heading } from "../../uikit/typography";

export const Header = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const { address, isConnecting } = useAccount();
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();
    const { chain } = useNetwork();

    const asset = Maybe.of(assets[chain?.nativeCurrency?.symbol as AssetSymbol]);

    return (
        <Flex as={"header"} h={"8.8rem"} alignItems={"center"} justifyContent={"space-between"}>
            <HStack spacing={{ base: 5, lg: 14 }}>
                <Link to={"/"}>
                    <Heading as={"h4"} textColor={ThemeColor.ACTION_PRIMARY}>
                        theGoblins
                    </Heading>
                </Link>
                <HStack as={"nav"} spacing={6}>
                    {/*<Link to={"/bank"}>Aggregator</Link>*/}
                    {/*<Link to={"https://docs.thegoblins.finance/"} target={"_blank"}>*/}
                    {/*    Docs*/}
                    {/*</Link>*/}
                </HStack>
            </HStack>

            <HStack spacing={4}>
                <Button
                    display={{ base: "none", sm: "inline-flex" }}
                    variant={"outline-gray"}
                    borderRadius={0}
                    leftIcon={
                        <Icon
                            name={`generic.${colorMode === "light" ? "lightMode" : "darkMode"}`}
                            w={"2rem"}
                            h={"2rem"}
                        />
                    }
                    iconSpacing={0}
                    paddingInline={0}
                    onClick={toggleColorMode}
                />
                {chain && openChainModal && (
                    <Button
                        borderRadius={0}
                        display={{ base: chain.unsupported ? "inline-flex" : "none", md: "inline-flex" }}
                        variant={"outline-gray"}
                        onClick={openChainModal}
                        {...(chain.unsupported && {
                            color: ThemeColor.ACTION_ERROR,
                            borderColor: ThemeColor.ACTION_ERROR,
                        })}
                    >
                        {chain.unsupported ? (
                            "Unsupported network"
                        ) : (
                            <HStack spacing={2}>
                                {asset.mapOr(<></>, ({ icon, color }) => (
                                    <Icon name={icon} fill={color} w={"2rem"} h={"2.rem"} />
                                ))}
                                <Text>{chain.name}</Text>
                            </HStack>
                        )}
                    </Button>
                )}
                {address && openAccountModal && (
                    <Button variant={"outline-gray"} borderRadius={0} onClick={openAccountModal}>
                        {formatAddress(address)}
                    </Button>
                )}
                {openConnectModal && (
                    <Button variant={"outline"} borderRadius={0} onClick={openConnectModal} isLoading={isConnecting}>
                        Connect Wallet
                    </Button>
                )}
            </HStack>
        </Flex>
    );
};
