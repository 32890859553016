import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { ThemeColor } from "../palette";

export const Button: ComponentStyleConfig = {
    baseStyle: {
        "& svg": {
            transitionProperty: "common",
            transitionDuration: "normal",
        },
    },
    sizes: {
        xs: {
            fontSize: "1.3rem",
            paddingInline: "1rem",
        },
        sm: {
            fontSize: "1.6rem",
            paddingInline: "1.2rem",
        },
        md: {
            fontSize: "1.6rem",
            paddingInline: "1.6rem",
        },
        lg: {
            height: 14,
            fontSize: "2.4rem",
            paddingInline: "1.6rem",
        },
    },
    variants: {
        solid: {
            color: "white",
            bg: ThemeColor.ACTION_PRIMARY,
            _hover: {
                bg: ThemeColor.ACTION_PRIMARY_HOVER,
                _disabled: {
                    bg: ThemeColor.ACTION_PRIMARY,
                },
            },
            _active: {
                bg: ThemeColor.ACTION_PRIMARY,
            },
        },
        outline: {
            color: ThemeColor.ACTION_PRIMARY,
            borderColor: ThemeColor.ACTION_PRIMARY,
            _hover: {
                color: "white",
                bg: ThemeColor.ACTION_PRIMARY,
                _disabled: {
                    color: ThemeColor.ACTION_PRIMARY,
                },
            },
            _active: {
                color: "white",
                bg: ThemeColor.ACTION_PRIMARY,
            },
        },
        "outline-gray": (props: StyleFunctionProps) => ({
            ...theme.components.Button.variants?.outline(props),
            color: ThemeColor.CONTENT,
            borderColor: ThemeColor.LINES,
            _hover: {
                color: ThemeColor.ACTION_PRIMARY,
                "& svg": {
                    fill: ThemeColor.ACTION_PRIMARY,
                },
                _disabled: {
                    color: ThemeColor.CONTENT,
                },
            },
            _active: {
                bg: ThemeColor.BACKGROUND,
            },
        }),
    },
};
