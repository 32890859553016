import React from "react";
import {
    Overview,
    StrategyHeader,
    WalletAlpha2,
    TermsModal,
    Performance,
    ChartAlpha2,
    StrategyExtras,
} from "src/components";
import { Strategy, useAllocationData, usePerformanceData, useContractsData, useStrategyClient } from "src/contracts";
import { assets, AssetSymbol } from "src/theme";
import { Column, Template } from "src/uikit/template";
import { Maybe } from "true-myth";
import { useAccount } from "wagmi";
import { Box, Text } from "@chakra-ui/react";
import { TooltipLabel } from "../uikit";
import { Allocation } from "../components/Allocation";
import { useStrategyStatsStore } from "../stores/alphaVaultStatsStore";
import { DeployedAmount } from "../components/DeployedAmount/DeployedAmount";

const STATS_REFRESH_INTERVAL = 10000;

interface Props<T extends Strategy> {
    strategy: T;
    strategySymbol: AssetSymbol;
    notice?: JSX.Element;
}

export const Aggregator = <T extends Strategy>({ strategy, strategySymbol, notice }: Props<T>) => {
    const client = useStrategyClient(strategy);
    const { address } = useAccount();
    const resetStats = useStrategyStatsStore(state => state.resetStats);
    const refreshStats = useStrategyStatsStore(state => state.refreshStats);
    const refreshStrategyStats = () => refreshStats(client, Maybe.of(address));
    const { performanceData } = usePerformanceData(strategy);
    const { allocationData } = useAllocationData(strategy);
    const { contractsData } = useContractsData(strategy);

    React.useEffect(() => {
        resetStats();
        refreshStrategyStats();
        const interval = setInterval(refreshStrategyStats, STATS_REFRESH_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [client, address]);

    return (
        <>
            <TermsModal />
            {notice && <Box mb={{ base: "1.6rem", sm: "2rem", md: "2.6rem" }}>{notice}</Box>}

            <StrategyHeader
                order={1}
                name={"Goblin Bank"}
                subtitle={"Alpha #2"}
                symbol={strategySymbol}
                averageApy={Maybe.of(performanceData?.last24hApy)}
                weekApy={Maybe.of(performanceData?.last7dApy)}
                inceptionApy={Maybe.of(performanceData?.historicalPerformance.apy)}
                currentEvent={Maybe.of()}
                showCounter={true}
                statistics={[
                    {
                        label: "Risk",
                        value: "Low",
                        tooltipLabel: (
                            <TooltipLabel heading={"Vault Risk"}>
                                The overall risk of the vault. For more information please refer to the risk sheet.
                            </TooltipLabel>
                        ),
                        icon: "generic.info",
                    },
                    {
                        label: "Lock-up",
                        value: "No",
                        tooltipLabel: (
                            <TooltipLabel heading={"Lock-up Period"}>
                                Time during which your funds are locked in order to execute the strategy.
                            </TooltipLabel>
                        ),
                        icon: "generic.locked",
                    },
                    {
                        label: "Protection",
                        value: "100%",
                        tooltipLabel: (
                            <TooltipLabel heading={"Capital Protected"}>Percentage of capital protection.</TooltipLabel>
                        ),
                        icon: "generic.protection",
                    },
                ]}
                apyLabel={"APY"}
                apyExplanation={""}
            />
            <Template rowGap={{ base: "1.6rem", sm: "2rem", md: "2.6rem", xl: "3.2rem" }}>
                <Column width={{ base: "100%", lg: "59.4325%", xl: "58.4325%" }}>
                    <Overview
                        body={
                            <>
                                <Text>
                                    The Bank is run by the Goblins who choose the best protocols on Arbitrum taking into
                                    account their APY but also their safety to bring you a mix between good returns and
                                    security for your assets.
                                </Text>
                                <Text mt={2}>
                                    Their magic formula to choose where to allocate the funds is a secret recipe but you
                                    can trust them, Goblins would not do anything that could put their gold at risk.
                                </Text>
                                <Text mt={2}>
                                    Still, they want to be transparent and just below you can find the protocols where
                                    the funds are currently allocated.
                                </Text>
                                <Text mt={2}>
                                    For their expertise, Goblins take 20% as performance fee. They will be distributed
                                    to the team, the treasury and the NFT stakers.
                                </Text>
                            </>
                        }
                        tableContent={[]}
                        order={2}
                    />
                    <Allocation data={Maybe.of(allocationData)} order={3} />
                    <br />
                    <Performance
                        bodyText={
                            "This chart represents the vault performance since the start of the strategy with a 10'000 USDC.e initial investment."
                        }
                        data={Maybe.of(performanceData)}
                        Chart={ChartAlpha2}
                        investmentValueFormatterOptions={{ suffix: ` ${assets[strategySymbol].displaySymbol}` }}
                        returnsValueFormatterOptions={{
                            maximumFractionDigits: assets[strategySymbol].maximumFractionDigits,
                        }}
                        order={4}
                    />
                </Column>
                <Column width={{ base: "100%", lg: "38.1622%", xl: "37.1622%" }}>
                    <br />
                    <DeployedAmount strategy={strategy} symbol={strategySymbol} order={2} />
                    <WalletAlpha2<T> strategy={strategy} symbol={strategySymbol} currentEvent={Maybe.of()} order={3} />
                    <StrategyExtras strategy={strategy} contracts={Maybe.of(contractsData)} order={4} />
                </Column>
            </Template>
        </>
    );
};
