import { Table, TableProps, Tbody, Td, Tr, Text, HStack, Image, Flex, Progress, Stack } from "@chakra-ui/react";
import React from "react";
import { AllocationItem } from "src/rest/internal";
import { ThemeColor } from "../../../theme/palette";
import { Link } from "../../Link";
import { ReactComponent as LogoStargate } from "../assets/logo-icon-stargate.svg";
import { ReactComponent as LogoBenqi } from "../assets/logo-icon-benqi.svg";
import { ReactComponent as LogoAave } from "../assets/logo-icon-aave.svg";
import { ReactComponent as LogoCompound } from "../assets/logo-icon-compound.svg";
import LogoTraderJoePNG from "../assets/logo-icon-traderjoe.png";
import { getColorValue } from "../../../theme/utils";

interface Props extends TableProps {
    data: AllocationItem[];
}

type AllocationProtocol = "Stargate" | "BenQi" | "Trader Joe" | "AAVE" | "Compound v3";

export const AllocationTable = ({ data, ...props }: Props) => {
    const renderProtocol = (protocol: AllocationProtocol): JSX.Element => {
        switch (protocol) {
            case "Stargate":
                return (
                    <Link to={"https://stargate.finance"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <LogoStargate height={"2rem"} width={"2rem"} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
            case "BenQi":
                return (
                    <Link to={"https://benqi.fi"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <LogoBenqi height={"2rem"} width={"2rem"} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
            case "Trader Joe":
                return (
                    <Link to={"https://traderjoexyz.com"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <Image src={LogoTraderJoePNG} height={"2rem"} width={"2rem"} alt={"TraderJoePNG"} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
            case "AAVE":
                return (
                    <Link to={"https://aave.com"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <LogoAave height={"2rem"} width={"2rem"} fill={getColorValue(ThemeColor.CONTENT)} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
            case "Compound v3":
                return (
                    <Link to={"https://compound.finance"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <LogoCompound height={"2rem"} width={"2rem"} fill={getColorValue(ThemeColor.CONTENT)} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
        }
    };

    return (
        <Table {...props}>
            <Tbody>
                {data.map(({ market, allocation }, index) => (
                    <Tr key={`${market}${index}`}>
                        <Td textAlign={"center"} fontWeight={"bold"} color={ThemeColor.CONTENT}>
                            <Stack spacing={5} paddingBottom={2}>
                                <Flex justifyContent={"space-between"} paddingTop={2}>
                                    <HStack>{renderProtocol(market as AllocationProtocol)}</HStack>
                                    <Text color={ThemeColor.ACTION_PRIMARY} fontWeight={"semibold"}>
                                        {allocation.toFixed(0)}%
                                    </Text>
                                </Flex>
                                <Progress size={"sm"} value={allocation} />
                            </Stack>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
