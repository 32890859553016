import { Flex, Heading, Center, Spinner } from "@chakra-ui/react";
import React from "react";
import { Card, CardProps } from "src/uikit";
import { Maybe } from "true-myth";
import { Strategy } from "../../contracts";
import { ContractsData } from "../../rest/internal";
import { AddressTable } from "./components/AddressTable";

interface Props<T extends Strategy> extends CardProps {
    strategy: T;
    contracts: Maybe<ContractsData>;
}

export const StrategyExtras = <T extends Strategy>({ strategy, contracts, ...props }: Props<T>) => {
    return (
        <Card py={"1.6rem"} {...props}>
            <Flex direction={"column"}>
                <Heading as={"h4"} fontSize={24}>
                    Contract Addresses
                </Heading>
                {contracts
                    .map(({ contracts }) => (
                        <>
                            <AddressTable strategy={strategy} data={contracts} />
                        </>
                    ))
                    .unwrapOr(
                        <Center h={"57.6rem"}>
                            <Spinner />
                        </Center>,
                    )}
            </Flex>
        </Card>
    );
};
