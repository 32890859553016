import React from "react";
import { PrivateApiService, PerformanceData } from "src/rest/internal";
import { Strategy } from "../types";

export const usePerformanceData = (strategy: Strategy) => {
    const privateApiService = new PrivateApiService(strategy);
    const [performanceData, setPerformanceData] = React.useState<PerformanceData>();

    const fetchPerformanceData = async () => {
        try {
            setPerformanceData(await privateApiService.getPerformance());
        } catch (e) {
            console.error(`Failed to fetch performance data: ${e}`);
        }
    };

    React.useEffect(() => {
        fetchPerformanceData();
    }, [strategy]);

    return { performanceData };
};
