import { Button, HStack, Input, Stack, Text } from "@chakra-ui/react";
import { useField } from "formik";
import { isEmpty, isNil } from "ramda";
import React from "react";
import { AssetSymbol } from "src/theme";
import { ThemeColor } from "src/theme/palette";
import { Asset } from "src/uikit";
import { BigDecimal, safeParseBigDecimal } from "src/utils/bigDecimal";
import { formatNumber } from "src/utils/format";

interface Props {
    name: string;
    availableAmount: BigDecimal;
    symbol: AssetSymbol;
    displaySymbol: string;
    isDisabled: boolean;
    minAmount?: BigDecimal | null;
    maxAmount?: BigDecimal | null;
    capRemainingAmount?: BigDecimal | null;
    maxButtonEnabled?: boolean;
}

export const AmountField = ({
    name,
    availableAmount,
    symbol,
    displaySymbol,
    isDisabled,
    minAmount,
    maxAmount,
    capRemainingAmount,
    maxButtonEnabled = true,
}: Props) => {
    const formatAmount = (amount: BigDecimal) =>
        formatNumber(amount.toNumber(), {
            suffix: displaySymbol,
            joinSeparator: " ",
            maximumFractionDigits: 8,
        });

    const validate = (val: string | number | null) => {
        if (isNil(val) || isEmpty(val)) return;

        return safeParseBigDecimal(val).match<string | void>({
            Ok: amount => {
                if (amount.gt(availableAmount)) return "The amount you entered exceeds your balance";
                if (minAmount && amount.lt(minAmount))
                    return `The amount you entered is below the minimum required amount of ${formatAmount(minAmount)}`;
                if (maxAmount && amount.gt(maxAmount))
                    return `The amount you entered is above the maximum allowed amount of ${formatAmount(maxAmount)}`;
                if (capRemainingAmount && amount.gt(capRemainingAmount))
                    return `The amount you entered exceeds the vault's cap`;
                if (amount.lte(0)) return "The amount you entered must be greater than 0";
            },
            Err: () => "The amount you entered is invalid",
        });
    };

    const [field, meta, helpers] = useField<string>({ name, validate });

    const onSetMaxAmount = () => {
        helpers.setValue(availableAmount.toString(), true);
    };

    return (
        <HStack
            p={4}
            spacing={{ base: 0, sm: 4 }}
            bg={ThemeColor.GENERAL}
            borderWidth={"1px"}
            borderColor={meta.error ? ThemeColor.ACTION_ERROR : ThemeColor.MID_RANGE}
            borderRadius={"0rem"}
        >
            <Asset
                display={{ base: "none", sm: "flex" }}
                symbol={symbol.replace(".", "") as AssetSymbol}
                opacity={isDisabled ? 0.5 : 1}
            />
            <Stack flex={1} spacing={0}>
                <Input
                    type={"text"}
                    inputMode={"decimal"}
                    fontSize={"3.2rem"}
                    fontWeight={"semibold"}
                    border={0}
                    borderRadius={"0rem"}
                    placeholder={"0.00"}
                    py={0}
                    px={2}
                    ml={"-0.8rem"}
                    autoComplete={"off"}
                    isDisabled={isDisabled}
                    {...field}
                />
                <Text fontSize={"1.2rem"} color={"gray.400"} opacity={isDisabled ? 0.5 : 1}>
                    Available:{" "}
                    {formatNumber(availableAmount.toNumber(), {
                        suffix: displaySymbol,
                        joinSeparator: " ",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 8,
                    })}
                </Text>
            </Stack>
            {maxButtonEnabled && (
                <Button
                    variant={"outline-gray"}
                    size={"sm"}
                    borderColor={"gray.500"}
                    onClick={onSetMaxAmount}
                    isDisabled={isDisabled}
                    borderRadius={0}
                    _hover={{
                        textColor: ThemeColor.ACTION_PRIMARY,
                        borderColor: ThemeColor.ACTION_PRIMARY,
                        transitionDuration: "0.4s",
                        transitionTimingFunction: "ease-in-out",
                    }}
                >
                    MAX
                </Button>
            )}
        </HStack>
    );
};
