import { Center, Flex, HStack, Spinner, Stack, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { shallow } from "zustand/shallow";
import { useStrategyStatsStore } from "src/stores/alphaVaultStatsStore";
import { AssetSymbol } from "src/theme";
import { Asset, Card, CardProps, StatProps, TooltipLabel } from "src/uikit";
import { Heading } from "src/uikit/typography";
import { CapProgress } from "./components/CapProgress";
import { Counter } from "./components/Counter";
import { Maybe } from "true-myth";
import { CalendarEvent } from "src/rest/internal";
import { Icon } from "../../uikit/icon";
import { ThemeColor } from "../../theme/palette";
import { ApyTable } from "./components/ApyTable";

interface Props extends CardProps {
    name: string;
    subtitle: string;
    symbol: AssetSymbol;
    averageApy: Maybe<number>;
    weekApy: Maybe<number>;
    inceptionApy: Maybe<number>;
    currentEvent: Maybe<CalendarEvent>;
    showCounter?: boolean;
    statistics: StatProps[];
    apyLabel: string;
    apyExplanation: string;
}

export const StrategyHeader = ({
    name,
    symbol,
    averageApy,
    weekApy,
    inceptionApy,
    currentEvent,
    showCounter = true,
    ...props
}: Props) => {
    const [counterEvent, setCounterEvent] = React.useState<CalendarEvent | null>(null);
    const stats = useStrategyStatsStore(
        state => ({ cap: state.cap, tvl: state.totalValueLocked, depositToken: state.depositToken }),
        shallow,
    );

    React.useEffect(() => {
        setCounterEvent(currentEvent.unwrapOr(null));
    }, [currentEvent]);

    return (
        <Card {...props}>
            <Stack spacing={{ base: 4, sm: 6 }}>
                <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "row" }} gap={"1.6rem"}>
                    <HStack spacing={4}>
                        <Asset symbol={symbol} size={"md"} />
                        <Stack>
                            <Heading as={"h2"}>{name}</Heading>
                        </Stack>
                    </HStack>
                    {averageApy
                        .chain(avgApy =>
                            inceptionApy.chain(incApy =>
                                weekApy.map(wApy => (
                                    // eslint-disable-next-line react/jsx-key
                                    <HStack spacing={4}>
                                        <Stack
                                            flex={1}
                                            spacing={0}
                                            flexDirection={{ base: "row", sm: "column" }}
                                            justifyContent={"space-between"}
                                        >
                                            <HStack justifyContent={"right"}>
                                                <Text color={"gray.500"}>APY (24h)</Text>
                                                <Tooltip
                                                    label={
                                                        <TooltipLabel>
                                                            <ApyTable day1={avgApy} week={wApy} inception={incApy} />
                                                        </TooltipLabel>
                                                    }
                                                >
                                                    <Flex>
                                                        <Icon
                                                            name={"generic.info"}
                                                            fill={ThemeColor.ACTION_PRIMARY}
                                                            w={"2rem"}
                                                            h={"2rem"}
                                                        />
                                                    </Flex>
                                                </Tooltip>
                                            </HStack>
                                            <Heading
                                                as={"h4"}
                                                fontSize={{ base: "2rem", sm: "3.8rem" }}
                                                lineHeight={{ base: "2rem", sm: "3.8rem" }}
                                            >
                                                {avgApy.toFixed(2)}%
                                            </Heading>
                                        </Stack>
                                    </HStack>
                                )),
                            ),
                        )
                        .unwrapOr(
                            <Center w={"7.2rem"} h={"7.2rem"}>
                                <Spinner />
                            </Center>,
                        )}
                </Flex>
                {counterEvent && showCounter && (
                    <Counter event={counterEvent} onComplete={() => setCounterEvent(null)} />
                )}
                {stats.cap.gt(0) && <CapProgress cap={stats.cap} tvl={stats.tvl} symbol={symbol} />}
            </Stack>
        </Card>
    );
};
