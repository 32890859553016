import React from "react";
import { Footer, Header } from "./components";
import { Box, Container, useColorMode } from "@chakra-ui/react";
import { chains } from "./wagmi";
import { darkTheme, lightTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { Aggregator, Home } from "./pages";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Strategy } from "./contracts";

export function App() {
    const { colorMode } = useColorMode();
    const { pathname } = useLocation();

    const getStrategyPage = (strategy: Strategy) => {
        switch (strategy) {
            case Strategy.Aggregator:
            default:
                return <Aggregator<Strategy.Aggregator> strategy={Strategy.Aggregator} strategySymbol={"USDCe"} />;
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <RainbowKitProvider chains={chains} theme={colorMode === "light" ? lightTheme() : darkTheme()}>
            <Container
                display={"flex"}
                flexDirection={"column"}
                minH={"100vh"}
                maxW={"container.xl"}
                paddingX={{ base: "1.8rem", sm: "2.8rem", md: "3.8rem", lg: "2rem", xl: "4.8rem" }}
            >
                <Header />
                <Box as={"main"} flex={1}>
                    <Routes>
                        <Route path={"/bank"} element={getStrategyPage(Strategy.Aggregator)} />
                        <Route index element={<Home />} />
                        <Route path={"*"} element={<Navigate to="/" replace />} />
                    </Routes>
                </Box>
                <Footer />
            </Container>
        </RainbowKitProvider>
    );
}
