import { Center, Flex, Spinner, TableContainer, Text } from "@chakra-ui/react";
import React from "react";
import { PerformanceData } from "src/rest/internal";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps, Notice } from "src/uikit";
import { Heading } from "src/uikit/typography";
import { FormatNumberOptions } from "src/utils/format";
import { Maybe } from "true-myth";
import { ChartAlpha2 } from "./components/ChartAlpha2";
import { EpochHistoryTable } from "./components/EpochHistoryTable";

type Chart = typeof ChartAlpha2;

interface Props extends CardProps {
    bodyText: string;
    data: Maybe<PerformanceData>;
    Chart: Chart;
    investmentValueFormatterOptions?: FormatNumberOptions;
    returnsValueFormatterOptions?: FormatNumberOptions;
}

export const Performance = ({ bodyText, data, Chart, ...props }: Props) => {
    return (
        <Card {...props}>
            <Flex direction={"column"}>
                <Heading as={"h4"} color={ThemeColor.ACTION_PRIMARY}>
                    Performance
                </Heading>
                {data
                    .map(({ historicalPerformance, epochPerformance }) => (
                        <>
                            <Text mt={4}>{bodyText}</Text>
                            {<Chart mt={6} data={historicalPerformance} />}
                            {epochPerformance && (
                                <>
                                    <Heading as={"h5"} mt={8}>
                                        Epoch History
                                    </Heading>
                                    {epochPerformance.length > 0 ? (
                                        <TableContainer mt={2}>
                                            <EpochHistoryTable variant={"info"} data={epochPerformance} />
                                        </TableContainer>
                                    ) : (
                                        <Notice color={ThemeColor.ACTION_PRIMARY} icon={"generic.info"} mt={4}>
                                            No epochs are completed yet.
                                        </Notice>
                                    )}
                                </>
                            )}
                        </>
                    ))
                    .unwrapOr(
                        <Center h={"57.6rem"}>
                            <Spinner />
                        </Center>,
                    )}
            </Flex>
        </Card>
    );
};
