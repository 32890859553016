import React from "react";
import { PrivateApiService, ContractsData } from "src/rest/internal";
import { Strategy } from "../types";

export const useContractsData = (strategy: Strategy) => {
    const privateApiService = new PrivateApiService(strategy);
    const [contractsData, setContractsData] = React.useState<ContractsData>();

    const fetchContractsData = async () => {
        try {
            setContractsData(await privateApiService.getContracts());
        } catch (e) {
            console.error(`Failed to fetch performance data: ${e}`);
        }
    };

    React.useEffect(() => {
        fetchContractsData();
    }, [strategy]);

    return { contractsData };
};
