import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { getAlphaColor } from "src/theme/utils";
import { Icon, IconName } from "./icon";

interface Props extends FlexProps {
    icon?: IconName;
}

export const Notice = ({ children, icon, color = ThemeColor.CONTENT, ...props }: Props) => (
    <Flex
        color={color}
        bg={getAlphaColor(color as string, 0.1)}
        borderRadius={"0rem"}
        px={"1.6rem"}
        py={"0.8rem"}
        alignItems={"center"}
        sx={{
            a: {
                color,
                fontWeight: "bold",
            },
            "a:hover": {
                color: getAlphaColor(color as string, 0.6),
            },
        }}
        {...props}
    >
        {icon && <Icon name={icon} fill={color} w={"2rem"} h={"2rem"} mt={"0.3rem"} mr={"1rem"} />}
        {children}
    </Flex>
);
