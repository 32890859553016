import { Theme } from "@chakra-ui/react";

export const radii: Partial<Theme["radii"]> = {
    none: "0",
    sm: "0.4rem",
    base: "0.6rem",
    md: "0.8rem",
    lg: "1rem",
    xl: "1.4rem",
    "2xl": "2rem",
    "3xl": "2.8rem",
    full: "9999px",
};
