import { BigDecimal, bigDecimal, BigDecimalSource, safeParseBigDecimal } from "./bigDecimal";

export const tokenUnitConverter = (decimals: number) => {
    const exponent = safeParseBigDecimal(decimals).map(b => b.toNumber());
    const base = bigDecimal(10);
    const formatMultiplier = exponent.map(exp => base.pow(exp));
    const parseMultiplier = exponent.map(exp => base.pow(-1 * exp));

    return {
        format: (amount: BigDecimal) => formatMultiplier.map(multiplier => amount.mul(multiplier).toFixed()),
        parse: (encodedAmount: BigDecimalSource) =>
            safeParseBigDecimal(encodedAmount).flatMap(amount =>
                parseMultiplier.map(multiplier => amount.mul(multiplier)),
            ),
    };
};

export type TokenUnitConverter = ReturnType<typeof tokenUnitConverter>;
