import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { ThemeColor } from "src/theme/palette";
import { useTheme } from "@emotion/react";

export interface CardProps extends BoxProps {}

export const Card = ({ children, ...props }: CardProps) => {
    const { semanticTokens } = useTheme();
    return (
        <Box
            bg={ThemeColor.CARDS}
            p={semanticTokens.sizes.cardPadding}
            borderRadius={0}
            borderWidth={0.5}
            borderColor={ThemeColor.LINES}
            {...props}
        >
            {children}
        </Box>
    );
};
