import { Center, Flex, Spinner, TableContainer } from "@chakra-ui/react";
import React from "react";
import { AllocationData } from "src/rest/internal";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps, Notice } from "src/uikit";
import { Heading } from "src/uikit/typography";
import { Maybe } from "true-myth";
import { AllocationTable } from "./components/AllocationTable";

interface Props extends CardProps {
    data: Maybe<AllocationData>;
}

export const Allocation = ({ data, ...props }: Props) => (
    <Card {...props}>
        <Flex direction={"column"}>
            <Heading as={"h4"} color={ThemeColor.ACTION_PRIMARY}>
                Strategy Allocation
            </Heading>
            {data
                .map(({ allocations }) => (
                    <>
                        {allocations.length > 0 ? (
                            <TableContainer mt={2}>
                                <AllocationTable variant={"info"} data={allocations} />
                            </TableContainer>
                        ) : (
                            <Notice color={ThemeColor.ACTION_PRIMARY} icon={"generic.info"} mt={4}>
                                The Goblins are hard at work allocating your funds. Come back soon.
                            </Notice>
                        )}
                    </>
                ))
                .unwrapOr(
                    <Center h={"57.6rem"}>
                        <Spinner />
                    </Center>,
                )}
        </Flex>
    </Card>
);
